import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import Auth from '@aws-amplify/auth';
import { createAuthLink } from 'aws-appsync-auth-link';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';

import awsconfig from '../aws-exports';

Auth.configure(awsconfig);
const getAccessToken = () => Auth.currentSession().then((session) => session.getIdToken().getJwtToken());
const config = {
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: awsconfig.aws_appsync_authenticationType,
    jwtToken: getAccessToken,
  },
  disableOffline: true,
};

const errorLink = onError((error) => {
  const { graphQLErrors, networkError } = error;

  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) console.log(`[Network error]: ${networkError}`, networkError);
});
const timeoutErrorLink = new ApolloLink((operation, forward) =>
  forward(operation).map((data) => {
    if (data && data.errors && data.errors.length > 0 && timeOutExists(data.errors)) {
      throw new Error('GraphQL Operational Error');
    }
    return data;
  })
);
const retryLink = new RetryLink();

const httpLink = createHttpLink({
  uri: config.url,
});
const link = ApolloLink.from([errorLink, retryLink, timeoutErrorLink, createAuthLink(config), httpLink]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({ addTypename: false }),
});

function timeOutExists(errors) {
  return errors.some((e) => e.message.includes('timed out'));
}
