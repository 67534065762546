import { configureStore } from '@reduxjs/toolkit';
import app from './app/reducer';

const store = configureStore({
  reducer: {
    app,
  },
  preloadedState: {},
});

export default store;
