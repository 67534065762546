const awsConfig = {
  "aws_project_region": "us-east-2",
  "aws_cognito_identity_pool_id": "privateAutoIdentityPool",
  "aws_cognito_region": "us-east-2",
  "aws_user_pools_id": "us-east-2_2QF4Ju3BD",
  "aws_user_pools_web_client_id": "77ugs6lsisf22fsdagbi6i07r2",
  "oauth": {
    "domain": "ecompreact3630114e-3630114e-kevdevv.auth.us-east-2.amazoncognito.com",
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": "http://localhost:8000/",
    "redirectSignOut": "http://localhost:8000/",
    "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_appsync_graphqlEndpoint": "https://swbmr6smmvhzfcl5bnje44znme.appsync-api.us-east-2.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_appsync_apiKey": "da2-2b6aby365ff4bczy3uxygfe3vm"
};
export default awsConfig;
