import React from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import store from 'redux/store';
import { client } from './client';

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </Provider>
);
