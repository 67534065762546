module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://comp-dev.leftfield.tech/graphql","verbose":true,"schema":{"perPage":5,"queryDepth":5,"typePrefix":"Wp","timeout":3000000,"requestConcurrency":4,"previewRequestConcurrency":0,"circularQueryLimit":5},"develop":{"nodeUpdateInterval":3000000,"hardCacheMediaFiles":true,"hardCacheData":false},"production":{"hardCacheMediaFiles":true,"allow404Images":false},"debug":{"graphql":{"writeQueriesToDisk":true,"showQueryVarsOnError":true,"showQueryOnError":true,"panicOnError":true,"onlyReportCriticalErrors":true,"copyQueryOnError":false,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"type":{"Post":{"limit":5000},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-chakra-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Endurica Companion","short_name":"EComp","start_url":"/","background_color":"#ffffff","theme_color":"#067bfe","display":"minimal-ui","icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"10d1db1793d8a714301d6182d46890ea"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
